import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeSVG from '../components/homesvg'

const IndexPage = () => (
  <Layout cssOverride="animate-change-background">
    <SEO title="Home" />
    <div className="grid grid-cols-1 md:grid-cols-2 md:p-0 md:pt-[10vh]">
      <div className="mb-[425px] w-full font-sans text-[2rem] font-light leading-tight md:mb-0 md:flex md:items-center">
        <div>
          <p className="pb-8">
            I am a product designer at <strong>Digitalocean</strong>, building cloud experience for developers.
          </p>
          <p>
            Before Digitalocean, I designed mobile apps to save lives at <strong>Motorola Solutions</strong>.
          </p>
        </div>
      </div>
      <div className="md:block">
        <HomeSVG />
      </div>
    </div>
  </Layout>
)

export default IndexPage
