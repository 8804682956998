import React from 'react'

const HomeSVG = () => {
  return (
    <div id="graphic" className="absolute bottom-[120px] right-0">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432.44 336.67" height="420">
        <title>tea-01</title>
        <g id="Layer_4" data-name="Layer 4">
          <rect className="cls-1" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1a" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1b" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1c" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1d" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1e" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1f" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1g" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1h" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
          <rect className="cls-1 cls-1i" x="166.8" y="124.33" width="16.89" height="6.42" rx="3.21" />
        </g>
        <g id="Layer_6" data-name="Layer 6">
          <circle className="cls-1" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1a" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1b" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1c" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1d" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1e" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1f" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1g" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1h" cx="181.86" cy="135.53" r="2.87" />
          <circle className="cls-1 cls-1i" cx="181.86" cy="135.53" r="2.87" />
        </g>
        <g id="Layer_2" data-name="Layer 2">
          <polygon className="cls-2" points="253.74 139.21 248.25 163.44 261.52 162.13 265.07 141.9 253.74 139.21" />
          <path className="cls-3" d="M263.93,138.59l27.92-5.87S287.4,159.8,287,163.44s-22.05,22.9-22.05,22.9l-6.27-23.07Z" />
          <path className="cls-4" d="M261,152.21l1.74.59a24.56,24.56,0,0,0,27.34-8.23c1.24-7.72,2.5-15.38,2.5-15.38l-27.92,5.86Z" />
          <path
            className="cls-3"
            d="M220.42,191.73S210,206,206.24,213.31c-8.36-11.06-19.26-32.91-20.5-39.92-1-5.65,1.62-18.62,1.62-18.62L164.43,148l-5.59,1.33L160,161.19s-1.4,9,8.59,8.45c4.85-.27,7.95,34.23,23.59,57.7,5.94,8.91,13.49,10.25,22.66,4.32s15.92-17.81,15.92-17.81l9.17-26.7Z"
          />
          <polygon className="cls-4" points="231.56 202.58 215.64 198.49 218.49 194.43 233.31 197.58 231.56 202.58" />
          <path
            className="cls-5"
            d="M230.6,304.74s-8.85,1-12.26,1c-5.85,0-6,15.34,2.44,22.15,11.85,9.51,34.95,8.7,62.5,8.16,7.56-.15,19.71-18,16.49-26.93-3.28-9.13-19.7-13-26.77-12.7C261.5,296.9,240.68,305.31,230.6,304.74Z"
          />
          <path
            className="cls-6"
            d="M317.82,309.35s9.27,1,12.84,1c6.13,0,6.63,12.54-2.25,19.34-12.41,9.51-49.69,7.1-65.16,5.23-7.86-1-21.25-12.25-17.87-21.19,3.43-9.13,20.63-13,28-12.7C285.46,301.51,307.26,309.92,317.82,309.35Z"
          />
          <path
            className="cls-3"
            d="M244.84,319.74s3.09,11.34,32.92,11.53c26.57.17,30.39-7.69,30.18-18.41s-43.39-2.63-52.83-9.11S244.84,319.74,244.84,319.74Z"
          />
          <path
            className="cls-7"
            d="M267.16,286.77s-26.67-7.28-44.9-20.23-29.5-24.28-51.59-23.47-31.25,24.1-20.61,34.64c6.17,6.11,14.72,28.49,96.87,47.91,8.9-1.62,11.73-17,11.73-17s34.69,9.48,73.24.41c20.64-4.86,55.85-35.61,54.23-55.84S361.85,237.41,358.21,239,277.27,290,267.16,286.77Z"
          />
          <rect className="cls-8" x="161.55" y="158.1" width="25.19" height="10.69" rx="5.35" />
          <path
            className="cls-9"
            d="M178.25,139.21c-9.24,0-16.73,2.48-16.73,5.53v1.38a8.46,8.46,0,0,0,.83,16.73,8.59,8.59,0,0,0,7.79,5h16.21a8.62,8.62,0,0,0,8.62-8.63V144.74C195,141.69,187.48,139.21,178.25,139.21Zm-20.48,15.23a5.33,5.33,0,0,1,3.75-5.08v9.85c0,.11,0,.21,0,.32A5.35,5.35,0,0,1,157.77,154.44Z"
          />
          <ellipse className="cls-10" cx="178.21" cy="144.19" rx="12.88" ry="2.54" />
          <path
            className="cls-11"
            d="M163.59,146.59c1.74,1.8,5.18,2.77,8,2.77-.07,5.13,0,13.66,0,16.9-3.23,0-6.27-1.15-8-4.52C163.54,156.72,163.52,151,163.59,146.59Z"
          />
          <path
            className="cls-12"
            d="M161.1,150.43c0-2.17-4.51-3.48-6.88-1.23s-2.88,14.69,6.92,10C161.17,153.25,161.14,152.45,161.1,150.43Z"
          />
          <rect
            className="cls-3"
            x="245.09"
            y="78.54"
            width="55.24"
            height="70.26"
            rx="27.02"
            transform="translate(51.18 -81.82) rotate(18.8)"
          />
          <path className="cls-13" d="M249.08,105.17s-.17,1.77,3,2.28,4.53-1.14,4.53-1.14" />
          <path className="cls-13" d="M266,109.29a3.69,3.69,0,0,0,3.19,3.5,4.87,4.87,0,0,0,4.65-1.48" />
          <path
            className="cls-2"
            d="M266.82,102.69s4.94-1.6,7.64-.52a9.15,9.15,0,0,1,4.14,3.91,15.41,15.41,0,0,0-5.21-2.14C271.79,103.68,270.06,103.1,266.82,102.69Z"
          />
          <path
            className="cls-2"
            d="M248.25,98.05c1.18.25,2.12.49,3,.63a15.52,15.52,0,0,1,5.21,2.14,9.14,9.14,0,0,0-4.15-3.91,7.93,7.93,0,0,0-3.55-.31Z"
          />
          <path
            className="cls-2"
            d="M275.42,80.28s-5.36,18.72,9,25.9,12.24,11.94,12.24,11.94-8.14,28.87-11.12,41.06c.59,4.36,25.89,12.75,31.76,13.06s-9.71-21.75-6.17-29.74,16.25-21.92,14.87-39C324.49,84.71,311.33,73,287.05,70.37c-2.46-4.64-13.56-10.93-22.36-8.6C253,64.87,247.39,76,250.83,94,256.9,85,263.38,78,275.42,80.28Z"
          />
          <path
            className="cls-3"
            d="M295.22,116.56s2.64-1.58,6.08,1.26,2.43,8.29-1.41,12.34-6.88,3.84-9.72,4.45S295.22,116.56,295.22,116.56Z"
          />
          <path className="cls-14" d="M258.67,308.27S234,297.35,221.44,288,204.71,265,194.33,257.29c36.55,30.88,60.21,44.08,60.21,44.08Z" />
          <path className="cls-14" d="M261.36,308.27S311,295.93,331.23,276s-34.93,12.68-34.93,12.68Z" />
          <path
            className="cls-15"
            d="M231.83,272.32l11.33-44.24s-22.13-8.36-13.22-31.22l-14.3-3s13.76-26,24-32.37a33.84,33.84,0,0,1,20.46-5s-.77,17.1,4.63,17.64,13-7,21.1-16.13c6.4.4,15.56,2.77,21,4.46,8.67,2.71,14.28,7.94,15.31,16.58,1.17,9.74,4.11,35.36,4.11,35.36l-23.64,1.38L302,206l-1.07,40.47,2.15,23.2S272.83,313.33,231.83,272.32Z"
          />
          <path
            className="cls-3"
            d="M303.92,215.67l.65,26.91s-25.71,20.61-32.77,31.57c-6.61,10.25-5.86,25.62-4.09,28.73,2,3.45,8.67,11.33,22.7,5.12,10.21-4.51,5.76-16.27-.11-15.72-5.15.48-4.12-5-3.13-10.31,6.38-9.94,34-24,36.42-30.08s.12-37.34.12-37.34Z"
          />
          <polygon className="cls-4" points="324 219.06 304.02 219.99 303.92 215.67 323.71 214.55 324 219.06" />
          <path className="cls-16" d="M247.74,122.42s2.42,3.28,9.22,1.25" />
          <path className="cls-17" d="M237.15,185.09s-5.91,6.78-7.21,11.77c-1.49-.34-3.45-.74-3.45-.74S231.49,187.42,237.15,185.09Z" />
        </g>
        <g id="Layer_5" data-name="Layer 5">
          <path
            className="cls-18"
            d="M80.47,336.67h24.42c17.75-4.79,30.7-19.72,30.7-37.41a36.93,36.93,0,0,0-11-26h1.49a2.83,2.83,0,0,0,0-5.65H59.3a2.83,2.83,0,0,0,0,5.65h1.49a36.89,36.89,0,0,0-11,26C49.78,317,62.73,331.88,80.47,336.67Z"
          />
          <path
            className="cls-19"
            d="M56.58,286.4a34.47,34.47,0,0,0-2.19,12c0,16.54,11.56,30.49,27.4,35h21.79c15.84-4.47,27.4-18.42,27.4-35a34.47,34.47,0,0,0-2.19-12Z"
          />
          <path
            className="cls-20"
            d="M76,301.48c3.16-.17,7-2.49,12.38-2.38,4.69.1,9.84,1.42,10.1,3.25.22,1.49-4.94,5.68-10.78,5.69-3.5,0-8.42-3.34-11.54-3.32-3.34,0-4.37,2.9-7.06,2.9-.92-1.3-.92-6.14,0-8.59C71.19,298.6,71.9,301.71,76,301.48Z"
          />
          <path
            className="cls-21"
            d="M83.39,299.66c-2.93.66-5.35,1.71-7.44,1.82-4,.23-4.76-2.88-6.94-2.45-.88,2.45-.88,7.29,0,8.59,2.69,0,3.72-2.89,7.06-2.9,2,0,4.68,1.33,7.28,2.31Z"
          />
          <circle className="cls-22" cx="94.7" cy="302.59" r="0.93" />
          <circle className="cls-23" cx="94.61" cy="302.51" r="0.37" />
          <path
            className="cls-24"
            d="M109,294.4c-2.8-.45-6.12,6.71-2.69,11.92,3.54,5.38-.17,7.23-1.16,12-.91,4.35,1.12,6.27,2,7.49s4.46-.31,4.46-.31-2.13-5.73-.72-7.89c3.36-5.14,2.29-8.06.76-13.32C110.77,301.12,114.55,295.32,109,294.4Z"
          />
          <path
            className="cls-25"
            d="M113.12,306.57c2.45.24,3.11,1.81,2.24,5.82-.55,2.58-2.29,4.11-2.68,5.81-.49,2.13.8,3.11,1,5,.36,3.06-2,4-2.77,4.75s-3.12-.51-3.12-.51a3,3,0,0,0,.28-3.07c-2-3.78,2-5.34.9-9C108.15,312.45,110.42,306.3,113.12,306.57Z"
          />
          <path
            className="cls-26"
            d="M81.76,333.36h21.8A38.86,38.86,0,0,0,119,324.84c-24.84,0-27.57-6.46-39-8.71a38.29,38.29,0,0,0-20,1.18A38.19,38.19,0,0,0,81.76,333.36Z"
          />
        </g>
      </svg>
    </div>
  )
}

export default HomeSVG
